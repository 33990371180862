import React from 'react';
import ReactDOM from 'react-dom';
import { configure } from 'mobx';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import ReactGA from 'react-ga4';
import { hotjar } from 'react-hotjar';

import './assets/index.scss';
import reportWebVitals from './reportWebVitals';
import config from './config';
import { AppShell } from './components/AppShell';
import { initSentry } from './config/sentry';

const {
    ga: { measurementId },
    hotjar: { hjid },
} = config();

if (measurementId) {
    ReactGA.initialize(measurementId);
    ReactGA.send('pageview');
}

if (hjid) {
    hotjar.initialize(hjid, 6);
}

initSentry();
configure({ enforceActions: 'never' });
library.add(faEllipsisV);

const Root = (
    <React.StrictMode>
        <AppShell />
    </React.StrictMode>
);

ReactDOM.render(Root, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

console.log('Build version 3.65.0 generated at: Fri Oct 04 2024 15:44:50 GMT+0000 (Coordinated Universal Time)');
